// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.works-square {
    padding: 20px;
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;
}

.masonry-grid {
    display: flex;
    margin-left: -10px; /* 调整列间距 */
    width: auto;
}

.masonry-grid-column {
    padding-left: 10px; /* 调整列间距 */
    background-clip: padding-box;
}

.masonry-grid-column > .image-unit {
    margin-bottom: 10px;
}

.loading-text, .no-works-text, .all-loaded-text {
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    color: #555;
    margin: 20px 0;
}

.loading-text {
    color: #888;
}

.no-works-text {
    color: #999;
}

.all-loaded-text {
    color: #4CAF50;
    font-weight: bold;
    position: sticky;
    bottom: 20px;
}

.bottom-sensor {
    width: 100%;
    height: 1px;
}

.masonry-grid {
    column-gap: 10px;
  }
  .masonry-grid-column {
    row-gap: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/WorksSquare.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,kBAAkB,EAAE,UAAU;IAC9B,WAAW;AACf;;AAEA;IACI,kBAAkB,EAAE,UAAU;IAC9B,4BAA4B;AAChC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,gBAAgB;EAClB;EACA;IACE,aAAa;EACf","sourcesContent":[".works-square {\n    padding: 20px;\n    width: 100%;\n    margin: 0 auto;\n    border-radius: 8px;\n}\n\n.masonry-grid {\n    display: flex;\n    margin-left: -10px; /* 调整列间距 */\n    width: auto;\n}\n\n.masonry-grid-column {\n    padding-left: 10px; /* 调整列间距 */\n    background-clip: padding-box;\n}\n\n.masonry-grid-column > .image-unit {\n    margin-bottom: 10px;\n}\n\n.loading-text, .no-works-text, .all-loaded-text {\n    width: 100%;\n    text-align: center;\n    font-size: 1.2em;\n    color: #555;\n    margin: 20px 0;\n}\n\n.loading-text {\n    color: #888;\n}\n\n.no-works-text {\n    color: #999;\n}\n\n.all-loaded-text {\n    color: #4CAF50;\n    font-weight: bold;\n    position: sticky;\n    bottom: 20px;\n}\n\n.bottom-sensor {\n    width: 100%;\n    height: 1px;\n}\n\n.masonry-grid {\n    column-gap: 10px;\n  }\n  .masonry-grid-column {\n    row-gap: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
