// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Transtyle.css */
.transtyle-container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.transtyle-layout {
  height: 100%;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
}

.transtyle-split {
  display: flex;
  height: 100%;
}

.custom-sider {
  padding: 20px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.4);
}
.controls-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.style-selector-container,
.reversion-strength-container {
  position: relative;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  align-items: center;
  background-color: rgb(35, 35, 35);
  margin-bottom: 16px;
}

.style-title {
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 14px;
  color: white;
}

.style-buttons,
.reversion-strength-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.style-button,
.reversion-strength-button {
  margin: 10px 5px;
  flex: 1 1 22%;
  text-align: center;
  max-width: 100px;
}

.control-buttons {
  padding: 0 20px;
}

.generate-button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/bridge/Transtyle.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,YAAY;EACZ,WAAW;EACX,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,oCAAoC;AACtC;AACA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;;EAEE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,iCAAiC;EACjC,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;EACf,YAAY;AACd;;AAEA;;EAEE,aAAa;EACb,eAAe;EACf,uBAAuB;EACvB,WAAW;AACb;;AAEA;;EAEE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* Transtyle.css */\n.transtyle-container {\n  height: 100%;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n\n.transtyle-layout {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n\n.transtyle-split {\n  display: flex;\n  height: 100%;\n}\n\n.custom-sider {\n  padding: 20px;\n  width: 400px;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n.controls-container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.style-selector-container,\n.reversion-strength-container {\n  position: relative;\n  width: 100%;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  border-radius: 10px;\n  align-items: center;\n  background-color: rgb(35, 35, 35);\n  margin-bottom: 16px;\n}\n\n.style-title {\n  position: absolute;\n  top: 5px;\n  left: 10px;\n  font-size: 14px;\n  color: white;\n}\n\n.style-buttons,\n.reversion-strength-buttons {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  width: 100%;\n}\n\n.style-button,\n.reversion-strength-button {\n  margin: 10px 5px;\n  flex: 1 1 22%;\n  text-align: center;\n  max-width: 100px;\n}\n\n.control-buttons {\n  padding: 0 20px;\n}\n\n.generate-button {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
