// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-page {
    padding: 20px;
    background-color: rgba(35,35,35,0.7);
    color: azure;
    height: 100%;
}

.form-group {
    margin-bottom: 20px;
}

.feedback-textarea {
    width: 400px;
    height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
}
`, "",{"version":3,"sources":["webpack://./src/pages/feedback/FeedbackPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oCAAoC;IACpC,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".feedback-page {\n    padding: 20px;\n    background-color: rgba(35,35,35,0.7);\n    color: azure;\n    height: 100%;\n}\n\n.form-group {\n    margin-bottom: 20px;\n}\n\n.feedback-textarea {\n    width: 400px;\n    height: 200px;\n    padding: 10px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
