// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transparent-select .ant-select-selector {
  background-color: transparent !important;
  color: white !important;
  min-width: 92px;
}

.transparent-select .ant-select-selection-item {
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/bridge/BridgeSelector.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".transparent-select .ant-select-selector {\n  background-color: transparent !important;\n  color: white !important;\n  min-width: 92px;\n}\n\n.transparent-select .ant-select-selection-item {\n  color: white !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
