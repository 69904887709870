// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* quickToolsIndex.css */

.quick-tools__container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding : 3%;
    gap: 1%;
}

.quick-tools__item {
    width: 10%;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 0px solid #ddd;
    border-radius: 8px;
    background-color: #242628;
    cursor: pointer;
    transition: transform 0.2s;
}

.quick-tools__item:hover {
    transform: translateY(-5px);
}

.quick-tools__image {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 4px;
}

.quick-tools__label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px;
    width: 100%;
    height: 100%;
    color: aliceblue;
    padding-left: 5%;
}

.quick-tools__description {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    color: gray;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: 5%;
}`, "",{"version":3,"sources":["webpack://./src/components/home/quickToolsIndex.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,aAAa;IACb,2BAA2B;IAC3B,eAAe;IACf,YAAY;IACZ,OAAO;AACX;;AAEA;IACI,UAAU;IACV,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,2BAA2B;IAC3B,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":["/* quickToolsIndex.css */\n\n.quick-tools__container {\n    display: flex;\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    padding : 3%;\n    gap: 1%;\n}\n\n.quick-tools__item {\n    width: 10%;\n    height: 200px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    border: 0px solid #ddd;\n    border-radius: 8px;\n    background-color: #242628;\n    cursor: pointer;\n    transition: transform 0.2s;\n}\n\n.quick-tools__item:hover {\n    transform: translateY(-5px);\n}\n\n.quick-tools__image {\n    width: 100%;\n    height: 120px;\n    object-fit: cover;\n    border-radius: 4px;\n}\n\n.quick-tools__label {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    font-size: 14px;\n    font-weight: bold;\n    text-align: center;\n    margin-top: 5px;\n    width: 100%;\n    height: 100%;\n    color: aliceblue;\n    padding-left: 5%;\n}\n\n.quick-tools__description {\n    font-size: 12px;\n    display: flex;\n    justify-content: flex-start;\n    color: gray;\n    text-align: center;\n    width: 100%;\n    height: 100%;\n    padding-left: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
