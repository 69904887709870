// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomInput.css */
.custom-box {
    max-height: 400px; /* 最大高度 */
    overflow-y: auto; /* 垂直滚动 */
    background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */
    color: white; /* 文本颜色 */
    border: 1px solid #000; /* 边框颜色 */
    padding: 10px; /* 内边距 */
    border-radius: 4px; /* 圆角 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 阴影 */
    z-index: 1000; /* 确保在其他元素之上 */
  }
  
.custom-input-wrapper .ant-input {
  width: 100%;
}

.custom-input-wrapper .ant-input-group-wrapper {
  display: flex;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Inpaint/CustomInput.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,iBAAiB,EAAE,SAAS;IAC5B,gBAAgB,EAAE,SAAS;IAC3B,oCAAoC,EAAE,UAAU;IAChD,YAAY,EAAE,SAAS;IACvB,sBAAsB,EAAE,SAAS;IACjC,aAAa,EAAE,QAAQ;IACvB,kBAAkB,EAAE,OAAO;IAC3B,wCAAwC,EAAE,OAAO;IACjD,aAAa,EAAE,cAAc;EAC/B;;AAEF;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":["/* CustomInput.css */\n.custom-box {\n    max-height: 400px; /* 最大高度 */\n    overflow-y: auto; /* 垂直滚动 */\n    background-color: rgba(0, 0, 0, 0.7); /* 半透明背景 */\n    color: white; /* 文本颜色 */\n    border: 1px solid #000; /* 边框颜色 */\n    padding: 10px; /* 内边距 */\n    border-radius: 4px; /* 圆角 */\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 阴影 */\n    z-index: 1000; /* 确保在其他元素之上 */\n  }\n  \n.custom-input-wrapper .ant-input {\n  width: 100%;\n}\n\n.custom-input-wrapper .ant-input-group-wrapper {\n  display: flex;\n  flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
