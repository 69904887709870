// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.label-style {
  font-size: 14px;
  margin-left: 10px;
  color: #ffffff;
  font-weight: bold;
}



.breadcrumb__path {
  margin-left: 6%;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2px;
}

.down-section {
  flex: 1 1;
}

.breadcrumb__down__section {
  flex: 1 1;
  overflow: auto;
  scrollbar-width: none; /* 针对 Firefox */
  -ms-overflow-style: none; /* 针对 Internet Explorer 和 Edge */
}

/* 针对 Webkit 浏览器 */
.breadcrumb__down__section::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/QuickPaint.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,iBAAiB;AACnB;;;;AAIA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,SAAO;EACP,cAAc;EACd,qBAAqB,EAAE,eAAe;EACtC,wBAAwB,EAAE,gCAAgC;AAC5D;;AAEA,kBAAkB;AAClB;EACE,aAAa;AACf","sourcesContent":["\n.label-style {\n  font-size: 14px;\n  margin-left: 10px;\n  color: #ffffff;\n  font-weight: bold;\n}\n\n\n\n.breadcrumb__path {\n  margin-left: 6%;\n  font-size: 18px;\n  font-weight: bold;\n  color: #ffffff;\n  margin-bottom: 2px;\n}\n\n.down-section {\n  flex: 1;\n}\n\n.breadcrumb__down__section {\n  flex: 1;\n  overflow: auto;\n  scrollbar-width: none; /* 针对 Firefox */\n  -ms-overflow-style: none; /* 针对 Internet Explorer 和 Edge */\n}\n\n/* 针对 Webkit 浏览器 */\n.breadcrumb__down__section::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
