// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Assistant/VideoModal.css */

.demo-video-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.demo-video-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 50%;
    width: 100%;
    position: relative; /* 为绝对定位的关闭按钮提供参考 */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.demo-video-close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    color: black; /* 关闭按钮的颜色 */
    cursor: pointer;
    transition: color 0.3s;
}

.demo-video-close:hover {
    color: red; /* 鼠标悬停时的颜色变化 */
}

video {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}


.video-tabs {
    display: flex;
    margin-bottom: 10px;
}

.video-tabs button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background: #f0f0f0;
    border-radius: 5px;
}

.video-tabs button.active {
    background: #007bff;
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Assistant/VideoModal.css"],"names":[],"mappings":"AAAA,6BAA6B;;AAE7B;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,WAAW;IACX,kBAAkB,EAAE,mBAAmB;IACvC,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,YAAY,EAAE,YAAY;IAC1B,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,UAAU,EAAE,eAAe;AAC/B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;;AAGA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":["/* Assistant/VideoModal.css */\n\n.demo-video-modal {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n}\n\n.demo-video-content {\n    background: white;\n    padding: 20px;\n    border-radius: 10px;\n    max-width: 50%;\n    width: 100%;\n    position: relative; /* 为绝对定位的关闭按钮提供参考 */\n    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);\n    text-align: center;\n}\n\n.demo-video-close {\n    position: absolute;\n    top: 10px;\n    right: 15px;\n    font-size: 24px;\n    color: black; /* 关闭按钮的颜色 */\n    cursor: pointer;\n    transition: color 0.3s;\n}\n\n.demo-video-close:hover {\n    color: red; /* 鼠标悬停时的颜色变化 */\n}\n\nvideo {\n    max-width: 100%;\n    height: auto;\n    border-radius: 5px;\n}\n\n\n.video-tabs {\n    display: flex;\n    margin-bottom: 10px;\n}\n\n.video-tabs button {\n    margin-right: 10px;\n    padding: 5px 10px;\n    cursor: pointer;\n    border: none;\n    background: #f0f0f0;\n    border-radius: 5px;\n}\n\n.video-tabs button.active {\n    background: #007bff;\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
