// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    overflow-y: auto; /* 启用滚动 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9); /* 半透明背景 */
    padding: 20px; /* 避免内容贴边 */
}

.full-screen-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}

.full-screen-content {
    background: #1c1c1c;
    color: #ccc;
    border-radius: 10px;
    width: 90%;
    max-width: 40%; /* 限制最大宽度 */
    padding: 40px 5%; /* 内边距 */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* 调整阴影 */
    overflow-y: auto; /* 启用滚动 */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
    max-height: 90vh; /* 限制高度，避免超出屏幕 */
    -webkit-user-select: none;
            user-select: none; /* 禁止文字选中 */
    pointer-events: auto; /* 确保按钮等交互仍可用 */
}

.full-screen-content::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
}
`, "",{"version":3,"sources":["webpack://./src/components/login/policy.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,aAAa;IACb,gBAAgB,EAAE,SAAS;IAC3B,qBAAqB,EAAE,YAAY;IACnC,wBAAwB,EAAE,WAAW;IACrC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B,EAAE,UAAU;IAC1C,aAAa,EAAE,WAAW;AAC9B;;AAEA;IACI,aAAa,EAAE,yBAAyB;AAC5C;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,mBAAmB;IACnB,UAAU;IACV,cAAc,EAAE,WAAW;IAC3B,gBAAgB,EAAE,QAAQ;IAC1B,yCAAyC,EAAE,SAAS;IACpD,gBAAgB,EAAE,SAAS;IAC3B,qBAAqB,EAAE,YAAY;IACnC,wBAAwB,EAAE,WAAW;IACrC,gBAAgB,EAAE,gBAAgB;IAClC,yBAAiB;YAAjB,iBAAiB,EAAE,WAAW;IAC9B,oBAAoB,EAAE,eAAe;AACzC;;AAEA;IACI,aAAa,EAAE,yBAAyB;AAC5C","sourcesContent":[".full-screen-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: 9999;\n    overflow-y: auto; /* 启用滚动 */\n    scrollbar-width: none; /* Firefox */\n    -ms-overflow-style: none; /* IE 10+ */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: rgba(0, 0, 0, 0.9); /* 半透明背景 */\n    padding: 20px; /* 避免内容贴边 */\n}\n\n.full-screen-container::-webkit-scrollbar {\n    display: none; /* Chrome, Safari, Edge */\n}\n\n.full-screen-content {\n    background: #1c1c1c;\n    color: #ccc;\n    border-radius: 10px;\n    width: 90%;\n    max-width: 40%; /* 限制最大宽度 */\n    padding: 40px 5%; /* 内边距 */\n    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* 调整阴影 */\n    overflow-y: auto; /* 启用滚动 */\n    scrollbar-width: none; /* Firefox */\n    -ms-overflow-style: none; /* IE 10+ */\n    max-height: 90vh; /* 限制高度，避免超出屏幕 */\n    user-select: none; /* 禁止文字选中 */\n    pointer-events: auto; /* 确保按钮等交互仍可用 */\n}\n\n.full-screen-content::-webkit-scrollbar {\n    display: none; /* Chrome, Safari, Edge */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
