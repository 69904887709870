// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watermark {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 20px;
    color: white;
    font-style: italic;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow for better visibility */
    pointer-events: none; /* Ensures the watermark doesn't interfere with other UI elements */
  }
  `, "",{"version":3,"sources":["webpack://./src/utils/canvas/Watermark.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,2CAA2C,EAAE,kDAAkD;IAC/F,oBAAoB,EAAE,mEAAmE;EAC3F","sourcesContent":[".watermark {\n    position: absolute;\n    bottom: 10px;\n    right: 10px;\n    font-size: 20px;\n    color: white;\n    font-style: italic;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional: adds a shadow for better visibility */\n    pointer-events: none; /* Ensures the watermark doesn't interfere with other UI elements */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
