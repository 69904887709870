// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.advanced-settings {
    width: 100%;
    display: flex;
  }
  
  .advanced-settings__toggle-button:hover {
    color: white !important;
    background-color: transparent !important;
  }

  
  .advanced-settings__toggle-button {
    color: white !important;
    width: 100%;
    margin-left: 1%;
    text-align: left;
    font-size: 18px; /* 调整字体大小 */
    background-color: transparent !important;
  }
  
  .advanced-settings__toggle-button-label {
    text-align: left;
  }

  .advanced-settings__controls {
    padding: 0 16px;
  }
  
  
  .advanced-settings__slider-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: white;
  }
  
  .advanced-settings__label {
    flex: 1 1; /* 让标签占用一部分宽度 */
    margin-right: 8px;
  }
  
  .advanced-settings__slider {
    flex: 2 1; /* 让 Slider 占用两倍宽度 */
    margin-right: 8px;
  }
  
  .advanced-settings__input-number {
    width: 80px;
    background-color: #3a3b3c;
    border: 0 solid;
    color: white !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/input/AdvancedSettings.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;EACf;;EAEA;IACE,uBAAuB;IACvB,wCAAwC;EAC1C;;;EAGA;IACE,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,eAAe,EAAE,WAAW;IAC5B,wCAAwC;EAC1C;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;;EAGA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,SAAO,EAAE,eAAe;IACxB,iBAAiB;EACnB;;EAEA;IACE,SAAO,EAAE,oBAAoB;IAC7B,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,eAAe;IACf,uBAAuB;EACzB","sourcesContent":[".advanced-settings {\n    width: 100%;\n    display: flex;\n  }\n  \n  .advanced-settings__toggle-button:hover {\n    color: white !important;\n    background-color: transparent !important;\n  }\n\n  \n  .advanced-settings__toggle-button {\n    color: white !important;\n    width: 100%;\n    margin-left: 1%;\n    text-align: left;\n    font-size: 18px; /* 调整字体大小 */\n    background-color: transparent !important;\n  }\n  \n  .advanced-settings__toggle-button-label {\n    text-align: left;\n  }\n\n  .advanced-settings__controls {\n    padding: 0 16px;\n  }\n  \n  \n  .advanced-settings__slider-group {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n    color: white;\n  }\n  \n  .advanced-settings__label {\n    flex: 1; /* 让标签占用一部分宽度 */\n    margin-right: 8px;\n  }\n  \n  .advanced-settings__slider {\n    flex: 2; /* 让 Slider 占用两倍宽度 */\n    margin-right: 8px;\n  }\n  \n  .advanced-settings__input-number {\n    width: 80px;\n    background-color: #3a3b3c;\n    border: 0 solid;\n    color: white !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
