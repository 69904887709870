// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.doodle-selector {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  border: 0px solid;
  background-color: rgba(0, 0, 0, 0.4);
}

.doodle-button {
  width: 100px;
  height: 60px;
  font-size: 16px;
  border-radius: 4px;
  color: #ddd;
  background-color: rgba(0, 0, 0, 0.4);
}

.doodle-button.active {
  background-color: #1890ff;
  color: white;
}

.doodle-button:first-child {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Inpaint/DoodleSelector.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,SAAS;EACT,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".doodle-selector {\n  border: 1px solid #ddd;\n  padding: 10px;\n  border-radius: 8px;\n  display: flex;\n  justify-content: flex-start;\n  gap: 10px;\n  border: 0px solid;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n\n.doodle-button {\n  width: 100px;\n  height: 60px;\n  font-size: 16px;\n  border-radius: 4px;\n  color: #ddd;\n  background-color: rgba(0, 0, 0, 0.4);\n}\n\n.doodle-button.active {\n  background-color: #1890ff;\n  color: white;\n}\n\n.doodle-button:first-child {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
