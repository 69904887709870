// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    padding: 30px;
    height: 100vh;
    margin: 0 auto;
    background-color: #1c1c1c;
    overflow: auto; /* 允许滚动 */
}

/* 隐藏滚动条的样式 */
.home-container::-webkit-scrollbar {
    width: 0; /* 隐藏垂直滚动条 */
}

.home-container::-webkit-scrollbar-thumb {
    background: transparent; /* 隐藏滚动条的滑块 */
}

.home-section {
    margin-bottom: 40px; /* 每个组件之间的间隔 */
    border-radius: 16px;
    position: relative;
}

.home-section home-section-Tools{
    margin-bottom: 40px; /* 每个组件之间的间隔 */
    border-radius: 16px;
    position: relative;
}

.home-section-title {
    position: absolute;
    top: -30px; /* 将标题放在左上角 */
    left: 20px;
    padding: 5px 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/home/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,cAAc;IACd,yBAAyB;IACzB,cAAc,EAAE,SAAS;AAC7B;;AAEA,aAAa;AACb;IACI,QAAQ,EAAE,YAAY;AAC1B;;AAEA;IACI,uBAAuB,EAAE,aAAa;AAC1C;;AAEA;IACI,mBAAmB,EAAE,cAAc;IACnC,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB,EAAE,cAAc;IACnC,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,UAAU,EAAE,aAAa;IACzB,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".home-container {\n    padding: 30px;\n    height: 100vh;\n    margin: 0 auto;\n    background-color: #1c1c1c;\n    overflow: auto; /* 允许滚动 */\n}\n\n/* 隐藏滚动条的样式 */\n.home-container::-webkit-scrollbar {\n    width: 0; /* 隐藏垂直滚动条 */\n}\n\n.home-container::-webkit-scrollbar-thumb {\n    background: transparent; /* 隐藏滚动条的滑块 */\n}\n\n.home-section {\n    margin-bottom: 40px; /* 每个组件之间的间隔 */\n    border-radius: 16px;\n    position: relative;\n}\n\n.home-section home-section-Tools{\n    margin-bottom: 40px; /* 每个组件之间的间隔 */\n    border-radius: 16px;\n    position: relative;\n}\n\n.home-section-title {\n    position: absolute;\n    top: -30px; /* 将标题放在左上角 */\n    left: 20px;\n    padding: 5px 10px;\n    font-size: 1.2em;\n    font-weight: bold;\n    color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
