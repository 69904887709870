// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/assets/styles/Inpaint.css */

.button-hover {
    background: black;
}

.custom-sider {
    padding: 20px;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.4); /* 半透明背景 */
}

.image-info {
    width: 100%;
    padding: 10px;
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    color: red;
    cursor: pointer;
}

.selected-image {
    max-width: 100%; /* 限制最大宽度 */
    max-height: 100%; /* 限制最大高度 */
    object-fit: contain; /* 保持图片比例 */
    margin: auto; /* 确保图片在容器中居中 */
}
  .upload-prompt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/bridge/Inpaint.css"],"names":[],"mappings":"AAAA,kCAAkC;;AAElC;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,oCAAoC,EAAE,UAAU;AACpD;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,eAAe,EAAE,WAAW;IAC5B,gBAAgB,EAAE,WAAW;IAC7B,mBAAmB,EAAE,WAAW;IAChC,YAAY,EAAE,eAAe;AACjC;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB","sourcesContent":["/* src/assets/styles/Inpaint.css */\n\n.button-hover {\n    background: black;\n}\n\n.custom-sider {\n    padding: 20px;\n    width: 400px;\n    background-color: rgba(0, 0, 0, 0.4); /* 半透明背景 */\n}\n\n.image-info {\n    width: 100%;\n    padding: 10px;\n}\n\n.close-icon {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    font-size: 24px;\n    color: red;\n    cursor: pointer;\n}\n\n.selected-image {\n    max-width: 100%; /* 限制最大宽度 */\n    max-height: 100%; /* 限制最大高度 */\n    object-fit: contain; /* 保持图片比例 */\n    margin: auto; /* 确保图片在容器中居中 */\n}\n  .upload-prompt {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    height: 100%;\n    color: white;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
