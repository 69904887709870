// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assistant-image {
    width: 80px; /* 根据需要调整大小 */
    height: 80px; /* 根据需要调整大小 */
    z-index: 1000; /* 确保图像位于页面顶部 */
    cursor: grab; /* 鼠标悬停时显示抓手 */
    -webkit-user-select: none;
            user-select: none; /* 禁止选择图像 */
}

.assistant-bubble {
    position: absolute;
    padding: 10px 15px;
    background-color: #ffd700; /* 醒目的黄色背景 */
    color: #333;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transform: translateY(0);
    animation: bounce 1s infinite; /* 弹跳动画 */
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* 鼠标悬停效果 */
.assistant-bubble:hover {
    background-color: #ffa500; /* 更加醒目的橙色 */
    transform: scale(1.05); /* 放大效果 */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px); /* 弹起 */
    }
    60% {
        transform: translateY(-5px); /* 轻微下落 */
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/Assistant.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,aAAa;IAC1B,YAAY,EAAE,aAAa;IAC3B,aAAa,EAAE,eAAe;IAC9B,YAAY,EAAE,cAAc;IAC5B,yBAAiB;YAAjB,iBAAiB,EAAE,WAAW;AAClC;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB,EAAE,YAAY;IACvC,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,2CAA2C;IAC3C,eAAe;IACf,wBAAwB;IACxB,6BAA6B,EAAE,SAAS;IACxC,2DAA2D;AAC/D;;AAEA,WAAW;AACX;IACI,yBAAyB,EAAE,YAAY;IACvC,sBAAsB,EAAE,SAAS;AACrC;;AAEA;IACI;QACI,wBAAwB;IAC5B;IACA;QACI,4BAA4B,EAAE,OAAO;IACzC;IACA;QACI,2BAA2B,EAAE,SAAS;IAC1C;AACJ","sourcesContent":[".assistant-image {\n    width: 80px; /* 根据需要调整大小 */\n    height: 80px; /* 根据需要调整大小 */\n    z-index: 1000; /* 确保图像位于页面顶部 */\n    cursor: grab; /* 鼠标悬停时显示抓手 */\n    user-select: none; /* 禁止选择图像 */\n}\n\n.assistant-bubble {\n    position: absolute;\n    padding: 10px 15px;\n    background-color: #ffd700; /* 醒目的黄色背景 */\n    color: #333;\n    font-size: 16px;\n    font-weight: bold;\n    border-radius: 20px;\n    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);\n    cursor: pointer;\n    transform: translateY(0);\n    animation: bounce 1s infinite; /* 弹跳动画 */\n    transition: background-color 0.3s ease, transform 0.2s ease;\n}\n\n/* 鼠标悬停效果 */\n.assistant-bubble:hover {\n    background-color: #ffa500; /* 更加醒目的橙色 */\n    transform: scale(1.05); /* 放大效果 */\n}\n\n@keyframes bounce {\n    0%, 20%, 50%, 80%, 100% {\n        transform: translateY(0);\n    }\n    40% {\n        transform: translateY(-10px); /* 弹起 */\n    }\n    60% {\n        transform: translateY(-5px); /* 轻微下落 */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
