// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container {
    display: flex;
    flex-direction: row;
    height: 100%;
  }
  
.l1-left-container,
.l1-middle-container,
.l1-right-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.l1-left-container {
  flex: 9 1;
  border-right: 1px solid #3a3b3c;
}

.l1-middle-container {
  flex: 28 1;
  border-right: 1px solid #3a3b3c;
}

.l1-right-container {
  flex: 9 1;
}


.l2-left-container,
.l2-right-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.l2-left-container {
  flex: 27 1;
  border-right: 1px solid #3a3b3c;
}


.l2-right-container {
  flex: 6 1;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/layout/main-layout.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;EACd;;AAEF;;;EAGE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,SAAO;EACP,+BAA+B;AACjC;;AAEA;EACE,UAAQ;EACR,+BAA+B;AACjC;;AAEA;EACE,SAAO;AACT;;;AAGA;;EAEE,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,UAAQ;EACR,+BAA+B;AACjC;;;AAGA;EACE,SAAO;AACT","sourcesContent":[".flex-container {\n    display: flex;\n    flex-direction: row;\n    height: 100%;\n  }\n  \n.l1-left-container,\n.l1-middle-container,\n.l1-right-container {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.l1-left-container {\n  flex: 9;\n  border-right: 1px solid #3a3b3c;\n}\n\n.l1-middle-container {\n  flex: 28;\n  border-right: 1px solid #3a3b3c;\n}\n\n.l1-right-container {\n  flex: 9;\n}\n\n\n.l2-left-container,\n.l2-right-container {\n  padding: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n.l2-left-container {\n  flex: 27;\n  border-right: 1px solid #3a3b3c;\n}\n\n\n.l2-right-container {\n  flex: 6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
