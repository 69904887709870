// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/assets/styles/SplitPane.css */
.gutter {
    background-color: #ddd;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  
  .gutter.gutter-horizontal {
    cursor: col-resize;
  }
  
  .gutter.gutter-vertical {
    cursor: row-resize;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/SplitPane.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,wBAAwB;EAC1B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB","sourcesContent":["/* src/assets/styles/SplitPane.css */\n.gutter {\n    background-color: #ddd;\n    background-repeat: no-repeat;\n    background-position: 50%;\n  }\n  \n  .gutter.gutter-horizontal {\n    cursor: col-resize;\n  }\n  \n  .gutter.gutter-vertical {\n    cursor: row-resize;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
