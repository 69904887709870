// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* MaskEditor.css */

.toolbar {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    display: flex;
    align-items: center;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin-right: 5px;
  }
  
  .icon-button svg {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: #fff;
  }
  
  .slider {
    margin-right: 5px;
  }
  
  .slider-label {
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/mask-editor/MaskEditor.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,UAAU;IACV,YAAY;EACd;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,YAAY;EACd","sourcesContent":["/* MaskEditor.css */\n\n.toolbar {\n    position: absolute;\n    top: 10px;\n    left: 10px;\n    z-index: 10;\n    display: flex;\n    align-items: center;\n  }\n  \n  .icon-button {\n    background: none;\n    border: none;\n    cursor: pointer;\n    padding: 5px;\n    margin-right: 5px;\n  }\n  \n  .icon-button svg {\n    width: 24px;\n    height: 24px;\n    fill: none;\n    stroke: #fff;\n  }\n  \n  .slider {\n    margin-right: 5px;\n  }\n  \n  .slider-label {\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
